import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Provider } from 'react-redux';
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import store from './store';
import Overlay from './Pages/Home/Overlay';
import Menu from './components/Menu';
import './components/styles.css';
import BackgroundBlur from './components/BackgroundBlur';
import LoadingScreen from './components/LoadingScreen';
import PCOverlay from './components/PCOverlay';
import { setUserData, setFriends, setReferralLink, setViewsToday, setAddTaskUrl, setDailyTaskCompleted, setDailyTappedCoins, setServerTime, setLastViewTime, setComboReward, setCardsPurchased, setEarnedCoins, setIncomePerHour, setBalance, setCards, setTappedCoins, setTonBalance, setLeagues, setPlayers, setLeagueThresholds, setEnergy, setUsername, setProfileImg, setDailyRewardDay, setLastLoginDate, setTasks } from './store/userSlice';
import CONFIG from './config';
import eruda from 'eruda';
import { preloadImages } from './utils/preloadImages';
import loadable from '@loadable/component';

const Home = loadable(() => import(/* webpackPreload: true */ './Pages/Home/Home'));
const Friends = loadable(() => import(/* webpackPreload: true */ './Pages/Friends/Friends'));
const Earn = loadable(() => import(/* webpackPreload: true */ './Pages/Tasks/Earn'));
const Wallet = loadable(() => import(/* webpackPreload: true */ './Pages/Wallet/Wallet'));
const Cards = loadable(() => import(/* webpackPreload: true */ './Pages/Upgrade/Cards'));
const Boosts = loadable(() => import(/* webpackPreload: true */ './Pages/Boosts/Boosts'));
const Leagues = loadable(() => import(/* webpackPreload: true */ './Pages/Leagues/Leagues'));



const importAll = (r) => r.keys().map(r);

// Загружаем изображения из папки assets
const imageUrls = importAll(require.context('../public/assets', false, /\.(png|jpe?g|svg|gif)$/));


const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    Home.preload();
    Friends.preload();
    Earn.preload();
    Wallet.preload();
    Cards.preload();
    Boosts.preload();
    Leagues.preload();
  }, []);

  useEffect(() => {
    if (location.pathname === '/wallet' || location.pathname === '/') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }    

    // Возвращаем overflow к стандартному значению при размонтировании
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/friends" element={<Friends />} />
        <Route path="/earn" element={<Earn />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/cards" element={<Cards />} />
        <Route path="/boosts" element={<Boosts />} />
        <Route path="/leagues" element={<Leagues />} />
      </Routes>
      {location.pathname !== '/boosts' && location.pathname !== '/leagues' && <Menu />}
      {location.pathname !== '/leagues' && <BackgroundBlur />}
      <Overlay />
    </>
  );
  
};

const AppContent = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasRunRef = useRef(false); // Ссылка для отслеживания первого запуска


  useEffect(() => {
    eruda.init();


    if (hasRunRef.current) {

      return; // Выход, если это не первый запуск
    }

    const performAsyncTasks = async () => {
      try {
        const isReferralHandled = await parseStartParam(dispatch);
        if (isReferralHandled) {
          // Логика, если параметр успешно обработан
        } else {
          await getUserData();
          await fetchUserData();
        }
        //preloadPages();
        // Предварительная загрузка изображений
        //console.log(imageUrls);
        Home.preload();
        Friends.preload();
        Earn.preload();
        Wallet.preload();
        Cards.preload();
        Boosts.preload();
        Leagues.preload();
        await preloadImages(imageUrls);
        //window.Telegram.WebApp.setHeaderColor('#031223');
    
        //await delay(3000);
        setLoading(false);
        navigate('/');
      } catch (error) {
        console.error('Error performing async tasks:', error);
      }
    };
    

    performAsyncTasks();
    hasRunRef.current = true; // Отметка, что задачи были выполнены
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const state = store.getState().user;
      const currentEnergy = state.energy;
      const currentMaxEnergy = state.maxEnergy;
      const currentEnergyRecoveryLevel = state.energyRecoveryLevel;
      const currentBalance = state.balance;
      const currentIncomePerHour = state.incomePerHour;
  
      regenerateEnergy(currentEnergy, currentMaxEnergy, currentEnergyRecoveryLevel, currentBalance, currentIncomePerHour);
    }, 1000);
    const sendDataToServerInterval = setInterval(sendDataToServer, 5000);

    // Очистка интервалов при размонтировании компонента
    return () => {
      clearInterval(intervalId);
      clearInterval(sendDataToServerInterval);
    };
  }, []);


  const parseStartParam = async (dispatch) => {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        const startParam = urlParams.get('tgWebAppStartParam');
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
        const username = window.Telegram.WebApp.initDataUnsafe.user.username || '';
        const fullName = `${window.Telegram.WebApp.initDataUnsafe.user.first_name || ''} ${window.Telegram.WebApp.initDataUnsafe.user.last_name || ''}`.trim();
        const profileImgUrl = window.Telegram.WebApp.initDataUnsafe.user.photo_url || '/assets/default_profile_img.png';
        const isPremium = window.Telegram.WebApp.initDataUnsafe.user.is_premium || false;
        const languageCode = window.Telegram.WebApp.initDataUnsafe.user.language_code || 'en';
        const reward = isPremium ? "50000|0,002" : "25000|0,001";
        //const reward = "25000|0,002";

        if (fullName && profileImgUrl) {
            dispatch(setUsername(fullName));
            dispatch(setProfileImg(profileImgUrl));


            const initData = window.Telegram.WebApp.initData; // Кодируем initData для использования в URL


            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}add_user.php?telegram_id=${userId}&username=${username}&full_name=${fullName}&profile_img_url=${profileImgUrl}&invited_by_code=${startParam || ''}&reward=${encodeURIComponent(reward)}&initData=${encodeURIComponent(initData)}&language_code=${languageCode}`);
                const data = await response.json();
                


                if (data.success) {

                    await fetchUserData();
                } else {

                    return false;
                }
            } catch (error) {

                return false;
            }

            return true;  // Параметры успешно обработаны
        } else {

        }

  
        return false;  // Параметры не найдены или не обработаны
    } catch (error) {

        return false;  // В случае ошибки возвращаем false
    }
 };

  
  
  

  const addUser = async (userId, username, profileImgUrl, fullName, invitedByCode) => {
    const initData = window.Telegram.WebApp.initData; // Кодируем initData для использования в URL

    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}add_user.php?telegram_id=${userId}&username=${username}&full_name=${fullName}&profile_img_url=${profileImgUrl}&invited_by_code=${invitedByCode}&initData=${encodeURIComponent(initData)}`);

        const data = await response.json();
        if (data.success) {

        } else {

        }
    } catch (error) {

    }
  };


  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const fetchUserData = async () => {
    if (window.Telegram && window.Telegram.WebApp) {
        const initData = window.Telegram.WebApp.initData; // Получаем initData
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
        try {
            // Передаем весь initData в запросе
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}get_balance_energy_and_league.php?telegram_id=${userId}&initData=${encodeURIComponent(initData)}`);
            const data = await response.json();
            
            if (data.success) {
                const serverTime = new Date(data.server_time);
                const lastUpdate = data.last_energy_update ? new Date(data.last_energy_update) : new Date();
                const energyRecoveryLevel = data.energy_recovery_level;
                const maxEnergy = 1500 + 500 * (data.energy_level - 1);

                // Calculate the time difference in minutes
                let timeDifferenceMinutes = 0;
                let timeDifference = (serverTime - lastUpdate) / 1000; // разница в секундах
                let maxTimeDifferenceSeconds = 3 * 60 * 60; // 3 часа в секундах

                timeDifferenceMinutes = Math.min(timeDifference, maxTimeDifferenceSeconds);

                // Prevent negative energy if the last update is in the future
                let currentEnergy = data.current_energy;
                if (timeDifferenceMinutes < 0) {
                    currentEnergy = maxEnergy;
                } else {
                    const energyRecovered = Math.floor(timeDifferenceMinutes * energyRecoveryLevel);

                    currentEnergy += energyRecovered;
                    if (currentEnergy > maxEnergy) {
                        currentEnergy = maxEnergy;
                    }
                }

                const incomePerHour = data.income_per_hour;
                let balanceIncrease = 0;
                let newBalance = data.balance;
                

                
                if (timeDifferenceMinutes > 0) {
                    balanceIncrease = Math.round((incomePerHour / 3600) * timeDifferenceMinutes);
                    newBalance += balanceIncrease;

                } else {

                }

                // Добавляем новые данные в Redux
                dispatch(setUserData({
                    balance: data.balance,
                    current_energy: currentEnergy,
                    max_energy: maxEnergy,
                    league: data.league,
                    level: data.level,
                    spent_coin: data.spent_coin,
                    damage_amount: data.damage_level,
                    referral_code: data.referral_code,
                    income_per_hour: data.income_per_hour,
                    energy_recovery_level: data.energy_recovery_level,
                    daily_full_energy_count: data.daily_full_energy_count,
                    daily_turbo_count: data.daily_turbo_count,
                    mining_bot_status: data.mining_bot_status
                }));
                dispatch(setFriends(data.friends));
                dispatch(setReferralLink(`${process.env.REACT_APP_TMA_URL}?startapp=${data.referral_code}`));
                dispatch(setLeagues(data.leagues));
                dispatch(setPlayers(data.players));
                dispatch(setDailyRewardDay(data.daily_reward_day)); // Добавляем daily_reward_day
                dispatch(setLastLoginDate(data.last_login_date));   // Добавляем last_login_date
                dispatch(setTonBalance(data.ton_balance));
                dispatch(setTappedCoins(data.tapped_coins));
                dispatch(setIncomePerHour(data.income_per_hour));
                dispatch(setCards(data.cards));
                dispatch(setCardsPurchased(data.cards_purchased));
                dispatch(setComboReward(data.combo_reward));
                dispatch(setEarnedCoins(balanceIncrease));
                dispatch(setViewsToday(data.views_today));
                dispatch(setLastViewTime(data.last_view_time));
                dispatch(setDailyTappedCoins(data.daily_tapped_coins));
                dispatch(setDailyTaskCompleted(data.daily_task_completed));
                dispatch(setAddTaskUrl(data.add_task_url));
                if (data.tasks) {
                  dispatch(setTasks(data.tasks));
                } else {
                  console.warn('No tasks found in the response');
                }

            } else {
                console.error('Error fetching user data:', data.error);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    } else {
        console.error('Telegram WebApp is not available');
    }
 };



  const getUserData = async () => {
    if (window.Telegram && window.Telegram.WebApp) {
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}get_user_profile.php?telegram_id=${userId}&initData=${encodeURIComponent(initData)}`);
        const data = await response.json();
        if (data.success) {
          const user = data.user;
          /*localStorage.setItem('userData', JSON.stringify({
            username: user.full_name,
            profileImg: user.profile_img_url,
          }));*/
          dispatch(setUsername(user.full_name));
          dispatch(setProfileImg(user.profile_img_url));
          return user;
        } else {

        }
      } catch (error) {

      }
    } else {

    }
  };

  const sendDataToServer = async () => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    const initData = window.Telegram.WebApp.initData;
    const languageCode = window.Telegram.WebApp.initDataUnsafe.user.language_code || 'en';
    const state = store.getState().user;
    const balance = state.balance;
    const currentLeague = state.currentLeague;
    const currentEnergy = state.energy;
    const lastEnergyUpdate = new Date().toISOString();
    const level = state.level;
    const tappedCoins = state.tappedCoins;
  
    if (balance !== null && currentLeague !== null && currentEnergy !== null && level !== null && tappedCoins !== null) {
        const data = {
            telegram_id: userId,
            balance: balance,
            league: currentLeague,
            current_energy: currentEnergy,
            last_energy_update: lastEnergyUpdate,
            level: level,
            tappedCoins: tappedCoins,
            language_code: languageCode,
            initData: initData
        };
  
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}update_user_and_boost.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            const result = await response.json();
            if (result.success) {

            } else {

            }
        } catch (error) {

        }
    } else {

    }
};
  
  useEffect(() => {
    if (loading || (isBrowser && !isTablet)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [loading]);

  const regenerateEnergy = (energy, maxEnergy, energyRecoveryLevel, balance, incomePerHour) => {
    if (
        !isNaN(energy) && 
        !isNaN(maxEnergy) && 
        !isNaN(energyRecoveryLevel) &&
        !isNaN(balance) &&
        !isNaN(incomePerHour)
    ) {
        // Обновление энергии
        const regeneratedEnergy = energy + energyRecoveryLevel;
        const newEnergy = Math.min(regeneratedEnergy, maxEnergy);
        dispatch(setEnergy(newEnergy));

        // Обновление баланса
        const incomePerSecond = incomePerHour / 3600; // Доход за одну секунду
        const newBalance = Math.round(balance + incomePerSecond);
        dispatch(setBalance(newBalance));
    } else {
        console.log("Invalid input values for energy regeneration or balance update.");
    }
 };


  /*if (isBrowser && !isTablet) {
    return <PCOverlay />;
  }*/

  return loading ? <LoadingScreen /> : <Layout />;
};

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;
